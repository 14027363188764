<template>
  <div>
    <!--    <van-nav-bar title="场均人次" left-text="返回" left-arrow @click-left="toTopPage()">-->
    <!--    </van-nav-bar>-->
    <div class="table">
      <div class="table-header">
        <div>月份</div>
        <div>场均人次</div>
        <div>本地场均人次</div>
      </div>
      <div class="table-content">
        <div
          class="table-content-item"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div>
            {{ item.mouth }}
          </div>
          <div>{{ item.averagePerson }}人</div>
          <div>{{ item.localAveragePerson }}人</div>
        </div>
      </div>
      <div class="table-content">
        <div class="table-content-item">
          <div>总平均</div>
          <div>{{ AvgSaleBySession }}元</div>
          <div>{{ CityAvgSaleBySession }}元</div>
        </div>
      </div>
    </div>
    <div class="desc">
      说明：页面展示1月至本月每个月平均场次人均数和本地城市平均场次人均数，总平均展示本影城场次人均数和本地城市场次人均数
    </div>
  </div>
</template>

<script>
import { zjApi } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'yearBoxOffice',
  data() {
    return {
      cinemaId: '',
      AvgSaleBySession: '',
      CityAvgSaleBySession: '',
      dataList: [
        {
          mouth: '1月',
          averagePerson: 32.2,
          localAveragePerson: 42.2,
        },
      ],
    }
  },
  mounted() {
    this.cinemaId = this.$route.query.id || ''
    this.getData()
  },
  methods: {
    toTopPage() {
      window.history.back()
    },
    getData() {
      const userCode = this.$store.state.userInfo.mobile
      const query = `{"UserCode":"${userCode}","CmdIndex":"101605","cmdArgs":["${this.cinemaId}"]}`
      zjApi.getPs({ ps: query }).then((r) => {
        const { data, Success, Message } = r
        if (!Success) {
          Toast(Message)
        } else {
          this.AvgSaleBySession = data.AvgSaleBySession
          this.CityAvgSaleBySession = data.CityAvgSaleBySession
          this.avgPrice = data.AvgPrice
          this.CityAvgPrice = data.CityAvgPrice
          this.dataList = data.monthDatas.map((item) => {
            return {
              mouth: `${item.m}月`,
              averagePerson: item.AvgSaleBySession,
              localAveragePerson: item.CityAvgSaleBySession,
            }
          })
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.van-nav-bar {
  background: #5087ec;
  // 因为style标签上有 lang='less'  所以.van-nav-bar__title 可以嵌套到.van-nav-bar里面
  /* /deep/ 就是把data-v-hash值选择器写到类名的前面 */
  /deep/ .van-nav-bar__title {
    color: white;
  }
  /deep/ .van-icon,
  /deep/ .van-nav-bar__text {
    color: white;
  }
}
.table {
  font-size: 0.4rem;
  &-header {
    background: #f2f2f2;
    display: flex;
    height: 1rem;
    align-items: center;
    > div {
      flex: 1;
      text-align: center;
    }
  }
  &-content {
    &-item {
      &:nth-child(2n) {
        background-color: #f6f7f8;
      }
      display: flex;
      height: 1.2rem;
      align-items: center;
      > div {
        flex: 1;
        text-align: center;
      }
    }
  }
}
.desc {
  padding: 0.3rem;
  font-size: 13px;
  color: rgba(93, 93, 96, 0.44);
}
.red {
  color: #ff5c5c;
}
.green {
  color: #5dea27;
}
</style>
